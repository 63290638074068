<template>
  <b-card>
    <b-form id="storeCasgOutEditForm" class="edit-form mt-2">
      <b-row>
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="店铺名称"
              label-for="store_name"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="store_name"
                size="sm"
                v-model="storeCashOut.store_name"
                @click="showSingleModal('店铺名称')"
                placeholder="点击搜索"/>
          </b-form-group>
        </b-col>
        <b-col
            cols="6"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="提现人:"
              label-for="getUserName"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="getUserName"
                label="title"
                :options="userArr"
                v-model="user"
                :clearable="false"
                @input="changeUser($event)"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="提现日期"
              label-for="get_date"
              label-size="sm"
              class="mb-1 required"
          >
            <flat-pickr
                id="get_date"
                v-model="storeCashOut.get_date"
                class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="提现金额"
              label-for="get_amount"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="get_amount"
                size="sm"
                type="number"
                @change="checkAmount($event,'get_amount')"
                v-model="storeCashOut.get_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="剩余提现金额"
              label-for="get_amount_left"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="get_amount_left"
                size="sm"
                type="number"
                @change="checkAmount($event,'get_amount_left')"
                v-model="storeCashOut.get_amount_left"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="限制提现金额"
              label-for="get_amount_limit"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="get_amount_limit"
                size="sm"
                type="number"
                @change="checkAmount($event,'get_amount_limit')"
                v-model="storeCashOut.get_amount_limit"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="备注"
              label-for="remark"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="remark"
                size="sm"
                v-model="storeCashOut.remark"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="附件"
              label-for="attachments"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload v-if="storeCashOut.loaded":theme="'files'"
                               :attachment_id="'attachments'"
                               :id="storeCashOut.attachments"
                               :object_type="'storeCashOut'"
                               :object_id="storeCashOut.invoice_id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!--店铺-->
    <b-modal
        id="modal-single-store"
        ok-only
        ok-title="确认"
        @ok="onSelectStore"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择店铺'"
        ref="storeModal"
    >
      <store-select ref="storeSelectReceive"></store-select>
    </b-modal>
  </b-card>
</template>


<script>

import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {computed, onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
// Notification
import {useToast} from 'vue-toastification/composition'
import {getCodeOptions, getCode,getCodeLabel, getCodeColor, avatarText, isEmpty, toDate} from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import storeCashOutListStore from "@/views/apps/storecashout/storeCashOutListStore";
import StoreSelect from "@/views/apps/storecashout/StoreSelect.vue";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BModal,
    BFormCheckbox,
    vSelect,
    useToast,
    flatPickr,
    AttachmentUpload,
    StoreSelect,
  },
  props:{
    cash_id :{
      type : Number,
      default:0
    },
  },
  data() {
    return {
      storeCashOut: ref({}),
      cashId:0,
      userArr:[],
      user: {},
    }
  },
  methods: {
    showSingleModal(type) {
      if (type === '店铺名称') {
        this.$refs['storeModal'].show()
      }
    },
    changeUser(event){
      this.storeCashOut.getUserName = event.title
      this.storeCashOut.get_user = event.id
    },
    onSelectStore() {
      let returnData = {}
      if (this.$refs.storeSelectReceive.getSelected().length > 0) {
        returnData = this.$refs.storeSelectReceive.getSelected()[0]

        this.storeCashOut.store_id = returnData.store_id
        this.storeCashOut.store_name = returnData.store_name
        this.storeCashOut.companyid = returnData.companyid
        this.storeCashOut.companyname = returnData.companyname
        this.storeCashOut.company_id = returnData.company_id
        this.storeCashOut.company_name = returnData.company_name
        this.$forceUpdate()
      }
    },
    getSaveInfo(){
      return this.storeCashOut;
    },
    checkAmount(value,name){
      if(value < 0){
        alert("金额需大于0");
        this.storeCashOut[name] = '';
      }
    },
  },
  setup(props) {
    // Register module
    if (!store.hasModule('storeCashOutEdit')) store.registerModule('storeCashOutEdit', storeCashOutListStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('storeCashOutEdit')) store.unregisterModule('storeCashOutEdit')
    })
    const toast = useToast()
    const onUploaded = function (id, attachment, result) {
      this.storeCashOut[id] = result
    }

    const edit = function () {
      store.dispatch('storeCashOutEdit/initEditPage', {cash_id:props.cash_id}).then(res => {
        this.storeCashOut = res.data.data
        this.storeCashOut.getUserName = getCodeLabel("user",res.data.data.get_user);
        this.storeCashOut.get_date = toDate(res.data.data.get_date);

        for (let i = 0; i < res.data.data.ext.userList.length; i++) {
          this.userArr.push({"title":res.data.data.ext.userList[i].full_name,"id":res.data.data.ext.userList[i].user_id})
        }
        this.user = {"title":this.storeCashOut.getUserName,"id":this.storeCashOut.get_user}
      })
    }

    return {
      toast,
      edit,
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      toDate,
      getCodeColor,
      onUploaded,

    }
  },
  created() {
    this.edit();
  },
}
</script>
