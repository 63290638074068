import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function storeCashOutList(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: '#'},
    { key: 'cash_no', label: '记录ID' , sortable: true},
    { key: 'companyname', label: '公司主体' , sortable: true},
    { key: 'company_name', label: '平台' , sortable: true},
    { key: 'store_name', label: '店铺' , sortable: true},
    { key: 'get_cash_status', label: '店铺提现'},
    { key: 'get_amount', label: '提现金额' , sortable: true},
    { key: 'get_amount_left', label: '剩余提现金额' , sortable: true},
    { key: 'get_amount_limit', label: '限制提现金额' , sortable: true},
    { key: 'get_user', label: '提现人' , sortable: true},
    { key: 'get_date', label: '提现日期' , sortable: true},
    { key: 'attachments', label: '附件' , sortable: true},
    { key: 'remark', label: '备注' , sortable: true},
    { key: 'reach_date', label: '提现到账日期' , sortable: true},
    { key: 'reach_amount', label: '提现到账金额' , sortable: true},
    // { key: 'attachments_reach', label: '提现到账附件' , sortable: true},
    { key: 'receive_date', label: '云贸收款日期' , sortable: true},
    { key: 'receive_amount', label: '云贸收款金额' , sortable: true},
    { key: 'attachments_receive', label: '云贸收款附件' , sortable: true},
    { key: 'status', label: '提现到账状态' , sortable: true},
    { key: 'actions', label: '操作'},
  ]

  const start = ref(1)
  const totalAmount = ref(0)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref(" create_time desc ")
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }
  const accAdd= function (arg1,arg2) {
    let r1,r2,m;
    try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
    try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
    m=Math.pow(10,Math.max(r1,r2))
    return (arg1*m+arg2*m)/m;
  }

  watch([start, limit], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['storeCashOutList/getCondition']}
    store
        .dispatch('storeCashOutList/search', {
          companyid: condition.companyid===null||condition.companyid===undefined?null:condition.companyid.id,
          company_id: condition.company_id===null||condition.company_id===undefined?null:condition.company_id.id,
          store_id: condition.store_id===null||condition.store_id===undefined?null:condition.store_id.id,
          get_user: condition.get_user===null||condition.get_user===undefined?null:condition.get_user.id,
          status: condition.status,
          get_date_str: condition.get_date_str,
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals
          totalAmount.value = 0;
          if(list && list.length>0){
            for(let i = 0 ;i<list.length;i++  ){
              let amount = list[i].get_amount;
              amount = amount===undefined?0:amount
              totalAmount.value = accAdd(totalAmount.value,amount);
            }
          }
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '提现记录列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    totalAmount,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,

  }
}
