<template>
  <div>
    <b-card>
      <div>
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="openAddPage"
                  class="ml-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-right">新增</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="exportData"
                  class="ml-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-right">导出</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="batchReceipt(3)"
                  class="ml-1"
              >
                <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                />
                <span class="align-right">已到账</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  @click="batchReceipt(2)"
                  class="ml-1"
              >
                <feather-icon
                    icon="SlashIcon"
                    class="mr-50"
                />
                <span class="align-right">未到账</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card
              v-show="advanced_search_modal">
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="公司主体:"
                    label-for="companyid"
                    label-size="sm"
                >
                  <v-select
                      id="companyid"
                      label="title"
                      :options="companyidArr"
                      v-model="condition.companyid"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="平台:"
                    label-for="company_id"
                    label-size="sm"
                >
                  <v-select
                      id="company_id"
                      label="title"
                      :options="company_idArr"
                      v-model="condition.company_id"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="店铺:"
                    label-for="store_id"
                    label-size="sm"
                >
                  <v-select
                      id="store_id"
                      label="title"
                      :options="store_idArr"
                      v-model="condition.store_id"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4" cols="12">
                <xy-input-button label="提现日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['get_date_str'])"
                                 :params="['get_date_str']"
                                 :value="condition.get_date_str"
                >
                </xy-input-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="提现人:"
                    label-for="get_user"
                    label-size="sm"
                >
                  <v-select
                      id="get_user"
                      label="title"
                      :options="get_userArr"
                      v-model="condition.get_user"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="提现到账状态:"
                    label-for="status"
                    label-size="sm"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('store_cash_out')"
                      :clearable="true"
                      v-model = "condition.status"
                      class="select-size-sm"
                      placeholder="选择提现到账状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </div>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="invoice_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <!--复选框-->
        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>
        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>

        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="5" variant="primary">
              <span><strong>合计</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{totalAmount}}</strong></span>
            </b-td>
            <b-td :colspan="13" variant="primary">
              <span><strong></strong></span>
            </b-td>
          </b-tr>
        </template>
        <!-- Columns -->
        <template #cell(cash_no)="data">
          {{data.item.cash_no}}
        </template>
        <template #cell(companyname)="data">
          {{data.item.companyname}}
        </template>
        <template #cell(company_name)="data">
          {{data.item.company_name}}
        </template>
        <template #cell(store_name)="data">
          {{data.item.store_name}}
        </template>
        <template #cell(get_cash_status)="data">
          {{ getCodeLabel('get_cash_status', data.item.ext.get_cash_status) }}
        </template>
        <template #cell(get_amount)="data">
          {{data.item.get_amount}}
        </template>
        <template #cell(get_amount_left)="data">
          {{data.item.get_amount_left}}
        </template>
        <template #cell(get_amount_limit)="data">
          {{data.item.get_amount_limit}}
        </template>
        <template #cell(get_user)="data">
          {{ getCodeLabel('user', data.item.get_user) }}
        </template>
        <template #cell(get_date)="data">
          {{ toDate(data.item.get_date) }}
        </template>
        <template #cell(attachments)="data">
          <div v-show="data.item.attachments !== undefined&&data.item.attachments !== '' ">
            <feather-icon icon="EyeIcon" @click="showAttachments(data.item.attachments)"/>
          </div>
        </template>
        <template #cell(remark)="data">
          <div :id="`remark-row-${data.item.cash_id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.cash_id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>
        <template #cell(reach_date)="data">
          {{ isEmpty(data.item.reach_date) ? "" : toDate(data.item.reach_date) }}
        </template>
        <template #cell(receive_date)="data">
          {{ isEmpty(data.item.receive_date) ? "" : toDate(data.item.receive_date) }}
        </template>
<!--        <template #cell(attachments_reach)="data">
          <div v-show="!isEmpty(data.item.attachments_reach)">
            <feather-icon icon="EyeIcon" @click="showAttachments(data.item.attachments_reach)"/>
          </div>
        </template>-->
        <template #cell(attachments_receive)="data">
          <div v-show="!isEmpty(data.item.attachments_receive)">
          <feather-icon icon="EyeIcon" @click="showAttachments(data.item.attachments_receive)"/>
        </div>
        </template>
        <template #cell(status)="data">
          {{ getCodeLabel('store_cash_out', data.item.status) }}
        </template>
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="isEmpty(data.item.reach_amount) && isEmpty(data.item.receive_amount)"
                             @click="openEditPage(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="isEmpty(data.item.reach_amount) && isEmpty(data.item.receive_amount)"
                             @click="deleteItem(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="isEmpty(data.item.reach_amount)"
                             @click="showReachModal(data.item)">
              <feather-icon icon="CheckCircleIcon"/>
              <span class="align-middle ml-50">提现到账</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="isEmpty(data.item.receive_amount)"
                             @click="showReceiveModal(data.item)">
              <feather-icon icon="DollarSignIcon"/>
              <span class="align-middle ml-50">云贸收款</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
      <!--/ form -->
      <b-modal
          id="loadingModal"
          centered
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
          ref="loadingModal"
      >
        <p class="my-4">正在处理，请勿关闭浏览器!</p>
      </b-modal>
      <b-modal
          id="modal-add"
          ok-title="确认"
          @ok="saveInfo"
          cancel-title="取消"
          centered
          size="lg"
          :title="'新增提现记录'"
          ref="addModal"
      >
        <store-cash-out-edit
            ref="addReceive" :cash_id="cash_id">
        </store-cash-out-edit>
      </b-modal>
      <b-modal
          id="modal-attachments"
          centered
          ok-only
          ok-title="确认"
          size="lg"
          title="查看附件"
          ref="attachModal"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments_ids"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload :theme="'files'"
                                 :attachment_id="'attachments_ch'"
                                 :id="attachmentsStr"
                                 :object_type="'storeCashOut'"
                                 :object_id="0"
                                 :readonly="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
          id="reachModal"
          ok-title="确认"
          ok-only
          @ok="confirmReach"
          centered
          size="sm"
          title="提现到账"
      >
        <b-row>
          <b-form-group label="请选择是否到账">
            <b-form-radio-group
            >
              <b-form-radio
                  v-model="status"
                  plain
                  name="some-radios3"
                  value="2"
              >
                未到账
              </b-form-radio>
              <b-form-radio
                  v-model="status"
                  plain
                  name="some-radios3"
                  value="3"
              >
                已到账
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

        </b-row>
      </b-modal>
      <b-modal
          id="receiveModal"
          ok-title="确认"
          ok-only
          @ok="confirmReceive"
          centered
          size="lg"
          title="云贸收款"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="云贸收款日期"
                label-for="receive_date"
                label-size="sm"
                class="mb-1 required"
            >
              <flat-pickr
                  id="receive_date"
                  v-model="receive_date"
                  class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="云贸收款金额"
                label-for="receive_amount"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="receive_amount"
                  size="sm"
                  type="number"
                  v-model="receive_amount"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="云贸收款附件"
                label-for="attachments_receive"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload :theme="'files'"
                                 :attachment_id="'attachments_receive'"
                                 :id="attachments_receive"
                                 :object_type="'storeCashOut'"
                                 :object_id="cash_id"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem,BFormRadio, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { toDate,avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions,isEmpty } from '@core/utils/filter'
import storeCashOutListStore from "@/views/apps/storecashout/storeCashOutListStore";
import storeCashOutEdit from "@/views/apps/storecashout/StoreCashOutEdit.vue";
import storeCashOutList from './storeCashOutList.js'
import axios from "@/libs/axios";
import XyInputButton from "@/views/components/xy/XyInputButton";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {second} from "@core/utils/dayjs/utils";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormRadio,
    BPagination,
    vSelect,
    XyInputButton,
    AttachmentUpload,
    storeCashOutEdit,
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value) => {
          tableIdArray.push(value.id)
        })
        this.checked.forEach((value) => {
          if (tableIdArray.indexOf(Number(value.id)) > -1) {
            show += 1;
          }
        })
        if (this.table.length === show && show > 0) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
    table: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value) => {
          tableIdArray.push(value.id)
        })
        this.checked.forEach((value) => {
          if (tableIdArray.indexOf(Number(value.id)) > -1) {
            show += 1;
          }
        })
        if (this.table.length === show && show > 0) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
  },
  data() {
    return {
      advanced_search_modal: false,
      companyidArr:[],
      company_idArr:[],
      store_idArr:[],
      get_userArr:[],
      cash_id:0,
      attachmentsStr: "",
      reach_date:'',
      reach_amount:0,
      attachments_reach:'',
      receive_date:'',
      receive_amount:0,
      attachments_receive:'',
      status:0,
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      checked: [],
    }
  },
  methods: {
    searchByCondition(){
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {status:'1'}
      store.commit('storeCashOutList/updateCondition', this.state.condition)
      this.refetchData()
    },
    openAddPage: function () {
      this.cash_id = 0;
      this.$refs['addModal'].show();
    },
    showAttachments: function (item) {
      this.attachmentsStr = item
      this.$refs['attachModal'].show()
    },
    openEditPage: function (item) {
      this.cash_id = item.cash_id;
      this.$refs['addModal'].show();
    },
    saveInfo: function (bvModalEvt){
      let saveInfo = this.$refs.addReceive.getSaveInfo();
      if(saveInfo.store_id === undefined||saveInfo.store_id === ''){
        alert("请选择店铺");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.get_user === undefined||saveInfo.get_user === ''){
        alert("请选择提现人");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.get_date === undefined||saveInfo.get_date === ''){
        alert("请选择提现日期");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.get_amount === undefined||saveInfo.get_amount === ''){
        alert("请填写提现金额");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.get_amount_left === undefined||saveInfo.get_amount_left === ''){
        alert("请填写剩余提现金额");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.get_amount_limit === undefined||saveInfo.get_amount_limit === ''){
        alert("请填写限制提现金额");
        bvModalEvt.preventDefault();
        return false;
      }
      if(parseFloat(saveInfo.get_amount_left)<parseFloat(saveInfo.get_amount_limit)){
        alert("剩余提现金额需大于等于限制提现金额");
        bvModalEvt.preventDefault();
        return false;
      }
      let objcheck = {}
      objcheck.get_date = second(saveInfo.get_date)
      objcheck.store_id = saveInfo.store_id
      this.$refs['loadingModal'].show()

      axios.post('/api/storecashout/checkExists', objcheck).then(res => {

        saveInfo.get_date = second(saveInfo.get_date);
        saveInfo.ext = null;
        if (res.data.data.ext.exists == true) {
          if (confirm("该日期，已有提现记录，是否继续添加?")){
            store
                .dispatch('storeCashOutList/save', saveInfo)
                .then(res => {
                  if (res.data.code === 0) {
                    this.refetchData()
                  } else {
                    alert(res.data.data)
                  }
                })
          }
        } else {
          store
              .dispatch('storeCashOutList/save', saveInfo)
              .then(res => {
                if (res.data.code === 0) {
                  this.refetchData()
                } else {
                  alert(res.data.data)
                }
              })
        }
        this.$refs['loadingModal'].hide()
      })
    },
    deleteItem: function (item) {
      this.$swal({
        title: "确定删除吗",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.$refs['loadingModal'].show()
          store
              .dispatch('storeCashOutList/deleteItem', {
                cash_id: item.cash_id,
              })
              .then(res => {
                this.$refs['loadingModal'].hide()
                if (res.data.code === 0) {
                  //弹窗
                  this.$swal({
                    icon: 'success',
                    title: '已删除!',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  this.refetchData()
                } else {
                  alert(res.data.data)
                }
              })
        }
      })
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {
    const toast = useToast()

    const state = reactive({
      condition: {},
    })
    // Register module
    if (!store.hasModule('storeCashOutList')) store.registerModule('storeCashOutList', storeCashOutListStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('storeCashOutList')) store.unregisterModule('storeCashOutList')
    })
    onMounted(() => {
      state.condition = store.getters['storeCashOutList/getCondition']
    })

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const advanced_search = function () {
      this.advanced_search_modal = !this.advanced_search_modal;
    }

    const exportData = function () {
      const condition = state.condition
      axios({
            url: '/api/exportexcelfile/exportStoreCashOut',
            method:'get',
            params: {
              companyid: condition.companyid===null||condition.companyid===undefined?null:condition.companyid.id,
              company_id: condition.company_id===null||condition.company_id===undefined?null:condition.company_id.id,
              store_id: condition.store_id===null||condition.store_id===undefined?null:condition.store_id.id,
              get_user: condition.get_user===null||condition.get_user===undefined?null:condition.get_user.id,
              status: condition.status,
              get_date_str: condition.get_date_str,
            },
            responseType: 'blob',
          }
      ).then(res => {
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "提现记录-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const initPage = function (){
      axios.post('/api/storecashout/initPage', {
      }).then(res => {
        const company_list = res.data.data.company_list;
        const plat_form_list = res.data.data.plat_form_list;
        const store_list = res.data.data.store_list;
        const user_list = res.data.data.user_list;
        for (let i = 0; i < company_list.length; i++) {
          this.companyidArr.push({"title":company_list[i].company_name,"id":company_list[i].company_id})
        }
        for (let i = 0; i < plat_form_list.length; i++) {
          this.company_idArr.push({"title":plat_form_list[i].company_name,"id":plat_form_list[i].company_id})
        }
        for (let i = 0; i < store_list.length; i++) {
          this.store_idArr.push({"title":store_list[i].store_name,"id":store_list[i].store_id})
        }
        for (let i = 0; i < user_list.length; i++) {
          this.get_userArr.push({"title":user_list[i].full_name,"id":user_list[i].user_id})
        }
      })
    }

    const showReachModal = function (item){
      this.status = item.status
      this.cash_id = item.cash_id
      /*this.reach_date = isEmpty(item.reach_date) ? '' : toDate(item.reach_date)
      this.reach_amount = isEmpty(item.reach_amount) ?  '' : item.reach_amount
      this.attachments_reach = isEmpty(item.attachments_reach) ? '' : item.attachments_reach*/
      this.$bvModal.show('reachModal')
    }

    const confirmReach = function (reachModal){
      store.dispatch('storeCashOutList/changeState', {cash_id:this.cash_id,status:this.status}).then(res => {
        if (res.data.code == 0) {
          this.$swal({
            icon: 'success',
            title: '操作成功!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
      /*let param = {}
      param.cash_id = this.cash_id
      param.reach_date = second(this.reach_date)
      param.reach_amount = this.reach_amount
      param.attachments_reach = this.attachments_reach
      if(isEmpty(param.reach_date)){
        toast.error('请选择提现到账日期')
        reachModal.preventDefault();
        return false
      }
      if(isEmpty(param.reach_amount)){
        toast.error('请填写提现到账金额')
        reachModal.preventDefault();
        return false
      }
      if (param.reach_amount < 0) {
        toast.error('提现到账金额不能为负')
        reachModal.preventDefault();
        return false
      }
      store.dispatch('storeCashOutList/save', param).then(res => {
        if (res.data.code == 0) {
          this.$swal({
            icon: 'success',
            title: '已提现!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })*/
    }

    const showReceiveModal = function (item){
      this.cash_id = item.cash_id
      this.receive_date = isEmpty(item.receive_date) ? toDate() : toDate(item.receive_date)
      this.receive_amount = isEmpty(item.receive_amount) ?  item.get_amount : item.receive_amount
      this.attachments_receive = isEmpty(item.attachments_receive) ? '' : item.attachments_receive
      this.$bvModal.show('receiveModal')
    }

    const confirmReceive = function (receiveModal){
      let param = {}
      param.cash_id = this.cash_id
      param.receive_date = second(this.receive_date)
      param.receive_amount = this.receive_amount
      param.attachments_receive = this.attachments_receive
      if(isEmpty(param.receive_date)){
        toast.error('请选择云贸收款日期')
        receiveModal.preventDefault();
        return false
      }
      if(isEmpty(param.receive_amount)){
        toast.error('请填写云贸收款金额')
        receiveModal.preventDefault();
        return false
      }
      if (param.receive_amount < 0) {
        toast.error('云贸收款金额不能为负')
        receiveModal.preventDefault();
        return false
      }
      store.dispatch('storeCashOutList/save', param).then(res => {
        if (res.data.code == 0) {
          this.$swal({
            icon: 'success',
            title: '已收款!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const onUploaded = function (id, attachment, result) {
      this[id] = result
    }

    const batchReceipt = function (status){
      if(this.checked.length < 1){
        toast.error('请选择提现记录')
        return false
      }
      let ids = []
      for(let i=0;i<this.checked.length;i++){
        if(this.checked[i].status == 3){
          toast.error('存在已到账的记录')
          return false
        }
        ids.push(this.checked[i].cash_id)
      }
      this.$refs['loadingModal'].show()
      store.dispatch('storeCashOutList/batchReceipt', {ids:ids.join(','),status:status}).then(res => {
        if (res.data.code == 0) {
          this.$refs['loadingModal'].hide()
          this.$swal({
            icon: 'success',
            title: '操作成功!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          //清空勾选
          this.clearSelected()
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    // 点击全选按钮
    const toggleAll = function (checked) {
      let checkedIds = [];
      this.checked.forEach(item => {
        checkedIds.push(Number(item.id))
      })
      this.table.forEach(element => {
        if (checked) {
          if (checkedIds.indexOf(element.id) === -1) {
            this.checked.push(element);
          }
        } else {
          //取消勾选
          for (let i = 0; i < this.checked.length; i++) {
            //在已勾选数组中找出要取消勾选的
            if (this.checked[i].id == element.id) {
              this.checked.splice(i, 1)
            }
          }
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      this.$forceUpdate()
    }
    // 复选框点击选中与取消
    const checkboxClick = function (item) {
      if (this.isCheckedObj['index' + item.id]) {
        //勾选
        this.checked.push(item)
      } else {
        //取消勾选
        for (let i = 0; i < this.checked.length; i++) {
          //在已勾选数组中找出要取消勾选的
          if (this.checked[i].id == item.id) {
            this.checked.splice(i, 1)
          }
        }
      }
      this.$forceUpdate()
    }
    //清空勾选
    const clearSelected = function () {
      this.checked.forEach(item => {
        this.isCheckedObj['index' + item.id] = false;
      })
      this.checked = []
    }

    const {
      searchList,
      totalAmount,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,


    } = storeCashOutList(
        {}
    )

    return {
      ...toRefs(state),
      state,
      searchList,
      totalAmount,
      initPage,
      fromChildren,
      advanced_search,
      exportData,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      showReachModal,
      showReceiveModal,
      onUploaded,
      confirmReach,
      confirmReceive,
      batchReceipt,
      toggleAll,
      checkboxClick,
      clearSelected,

      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      toDate,
      getCodeColor,
      getCodeOptions,
      isEmpty,
    }
  },
  created() {
    this.initPage()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
