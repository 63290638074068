import axios from '@axios'

export default {
  namespaced: true,
  state: {
    condition:{
      status:'1',
    },
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      state.condition = condition
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/storecashout/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/storecashout/deleteItem', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listStore(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/storecashout/listStore', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    initEditPage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/storecashout/initEditPage', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/storecashout/save', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    checkExists(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/storecashout/checkExists', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeState(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/storecashout/changeState', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    batchReceipt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/storecashout/batchReceipt', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
